:root {
  --dark-blue: #1A5276;
  --blue: #2980B9;
  --light-blue: #95d2fb;
  --yellow: #F7DC6F;
  --gold: #dbb51e;
  --green: #4CAF50;
  --light: #FDFEFE;
  --dark: #2C3E50;
  --dark-gray: #333;
  --light-gray: #d6dadd;
  --gray: #BDC3C7;
  --black: #000;
  --white: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light);
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
}

.container {
  padding: 2rem 0;  ;
}

.width {
  max-width: 1200px;
  margin: auto;
}

button {
  margin-top: 1rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background-color: var(--gold);
  /* color: var(--dark-gray); */
  border: none;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: var(--yellow);
}

a {
  text-decoration: none;
  color: var(--dark-gray);
}

h1 {
font-size: 3rem;
color: var(--dark-blue);
margin: 1rem 0;
text-align: center;
}

h2 {
  font-size: 2.2rem;
  margin: 1rem 0;
}

h3 {
  font-size: 1.8rem;
  margin: 1rem 0;
}

h4 {
  font-size: 1.4rem;
  margin: 1rem 0;
}

/* Header */
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--dark-blue);
}

.nav a {
  color: var(--light);
  text-decoration: none;
}

.nav a:hover {
  /* color: var(--light-blue); */
  text-decoration: underline;
  text-decoration-color: var(--yellow);
}

.nav svg {
  color: var(--light);
  cursor: pointer;
}

.links {
  display: flex;
  gap: 4rem;
}

.youtube {
  font-size: 1.5rem;
}

.youtube:hover {
  color: #ff0000;
}

/* Home */
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background: linear-gradient(270deg, rgba(253,254,254,0) 0%, rgba(253,254,254,1) 50%, rgba(253,254,254,0) 100%), url(/public/assets/images/band-3.jpg);
  background-size: cover;
  background-position: 50% 30%;
}

.home h3,
.home h4 {
  margin: 0;
}

.home img {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  object-fit: cover;
}

/* What We Offer */
.instruments {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  /* align-items: center; */
  gap: 1rem;
  margin: auto;
  margin-top: 2rem;
  text-align: center;
}

.instruments img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  transition: all 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.instruments img:hover {
  transform: scale(1.04);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* Recitals */
.recitals-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem auto;
}

.upcoming-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem;
  width: 100%;
  border-radius: 8px;
  /* background: linear-gradient(270deg, rgba(233,196,106,0) 0%, rgb(238, 238, 240, 1) 35%), url(/public/assets/images/sheet-music.jpg); */
  background: rgb(231, 231, 234);
}

.upcoming-container h3 {
  margin-top: 0;
}

.upcoming-container ul {
  list-style: none;
}

.upcoming-container li {
  padding: 0.2rem 0;
  margin: 1rem 0;
}

.upcoming-container svg {
  color: var(--blue);
  margin-right: 0.5rem;
  scale: 1.3;
}

.upcoming-container a {
  text-decoration: underline;
}

/* Past Recitals */
.past-recitals {
  background-color: rgb(238, 238, 240);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 2rem;
}

/* About */
.about-container {
  margin: auto;
  padding: 2rem;
}

.about {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin: auto;
  margin-bottom: 2rem;
}

.about img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  justify-self: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.skills-and-qualifications {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  justify-content: center;
  align-items: center;
  margin: 4rem auto;
}

.skills-and-qualifications h4 {
  margin: 0;
  margin-bottom: 1rem;
}

.skills-and-qualifications div {
  /* border: var(--green) 2px solid; */
  /* background: var(--blue); */
  background: rgb(231, 231, 234);
  /* background: linear-gradient(240deg, rgba(133,193,233,1) 0%, rgba(26,82,118,1) 80%); */
  /* color: var(--white); */
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  position: relative;
}

.skills-and-qualifications div:hover {
  background: var(--blue);
  color: var(--white);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  scale: 1.03;
}

.skills-and-qualifications ul {
  list-style: none;
  font-size: 1.1rem;
}

.skills-and-qualifications li {
  margin: 0.3rem 0;
}

.skills-and-qualifications svg {
  color: var(--blue);
  margin-right: 0.5rem;
  margin-bottom: -0.1rem;
}

.skills-and-qualifications div:hover svg {
  color: var(--white);
}

.pricing {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin: auto;
}

.pricing p {
  margin-bottom: 0.5rem;
}

.pricing img {
  width: 100%;
  height: auto;
  object-fit: cover;
  justify-self: center;
  border-radius: 8px;
}

/* Contact */
.contact {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-self: end;
  gap: 4rem;
  padding-top: 0;
}

.contact-form {
  display: flex;
  flex-direction: column;
  /* max-width: 500px; */
  width: 100%;
  margin: auto;
}

label {
  margin-top: 1.5rem;
}

input, textarea {
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: auto;
}

.contact-info p {
  margin-bottom: 0.5rem;
}

.contact-info img {
  width: 100%;
  height: auto;
  object-fit: cover;
  justify-self: center;
  border-radius: 8px;
}

.thank-you {
  background: linear-gradient(90deg, rgba(189,195,199,0.7) 0%, rgba(189,195,199,0.7) 100%), url(/public/assets/images/thank-you.jpg); 
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 2rem;
  padding: 2rem;
  text-align: center;
  min-height: 25vh;
}

.thank-you h4 {
  font-size: 6rem;
}

/* Footer */
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: var(--dark-blue);
  color: var(--light);
}

@media (max-width: 1024px) {
  .container {
    padding: 1rem;
  }

  .links {
    gap: 2rem;
  }

  .home h1, 
  .home h3,
  .home h4 {
    text-align: center;
    color: black;
  }

  .instruments {
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 1rem;
    margin-top: 2rem;
  }

  .instruments {
    grid-template-columns: repeat(3, 1fr);
  }

  .upcoming-container {
    justify-content: center;
    padding: 1rem;
    text-align: center;
  }

  .about-container {
    padding: 1rem;
  }

  .about {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 2rem;
  }

  .skills-and-qualifications {
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
  }

  .pricing {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .contact {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .contact label {
    margin-top: 1rem;
  }

  .contact-info {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .thank-you {
    margin-top: 0;
  }

  .footer-container {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .links {
    gap: 2rem;
  }

  .instruments {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 1rem;
    margin-top: 2rem;
  }

  .instruments {
    grid-template-columns: repeat(3, 1fr);
  }

  .about-container {
    padding: 1rem;
  }

  .about {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 2rem;
  }

  .skills-and-qualifications {
    grid-template-columns: 1fr;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
  }

  .pricing {
    grid-template-columns: 1fr;
  }

  .contact label {
    margin-top: 1rem;
  }

  .contact-info {
    grid-template-columns: 1fr;
  }

  .footer-container {
    text-align: center;
  }
}
